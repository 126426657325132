import React, { Component } from 'react';
import { array, string, func, number } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import clsx from 'clsx';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing } from '../../util/data';
import { richText } from '../../util/richText';
import { findOptionsForSelectFilter } from '../../util/search';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import {
  NamedLink,
  IconFreeCancellation,
  IconClock,
  IconBus,
  IconFullStar,
  IconHalfStar,
  IconEmptyStar,
} from '../../components';

import {
  TourlyResponsiveImage,
  CARD_SIZES,
  StandardDPRVariants,
  getTourlyProxyServer,
  processVariantsForImage
} from '../../util/responsiveImagesHelper';

import { types as sdkTypes } from '../../util/sdkLoader';
import css from './ListingCard.module.css';

import christmasfooter from '../../assets/christmas_border_bottom.png';

const { Money } = sdkTypes;

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const getCertificateInfo = (certificateOptions, key) => {
  return certificateOptions.find(c => c.key === key);
};

export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    filtersConfig,
    setActiveListing,
    index,
    width
  } = props;
  const classes = clsx(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const firstImageURL =
    firstImage !== null ? firstImage.attributes.variants['landscape-crop4x'].url : '';

  const certificateOptions = findOptionsForSelectFilter('certificate', filtersConfig);
  const certificate = publicData
    ? getCertificateInfo(certificateOptions, publicData.certificate)
    : null;
  const duration = publicData.duration;
  const discount =
    typeof listing.attributes.publicData.discountPercentage !== 'undefined'
      ? listing.attributes.publicData.discountPercentage
      : 0;

  const googleRating =
    typeof listing.attributes.publicData.googleRating !== 'undefined'
      ? listing.attributes.publicData.googleRating
      : 0;

  const discountedPrice = Object.create(price);
  discountedPrice.amount = (100 + discount) * 0.01 * discountedPrice.amount;
  const { formattedPrice, priceTitle } = priceData(price, intl);
  const priceDiscountedValue = priceData(
    new Money((100 + discount) * 0.01 * price.amount, price.currency),
    intl
  );

  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey =
    publicData && publicData.groupTour
      ? 'ListingCard.perGroup'
      : isNightly
      ? 'ListingCard.perNight'
      : isDaily
      ? 'ListingCard.perDay'
      : 'ListingCard.perUnit';

  const starRating = id => {
    if (googleRating >= id - 0.2) {
      return <IconFullStar className={css.ratingStar} />;
    } else if (googleRating >= id - 0.5) {
      return <IconHalfStar className={css.ratingStar} />;
    } else {
      return <IconEmptyStar className={css.ratingStar} />;
    }
  };

  const proxyServer = getTourlyProxyServer(window.location.origin);
  const processedVariants = processVariantsForImage(
    true,
    width,
    -1,
    proxyServer.toString(),
    firstImageURL,
    StandardDPRVariants
  );

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <div
        className={css.threeToTwoWrapper}
        onMouseEnter={() => setActiveListing(currentListing.id)}
        onMouseLeave={() => setActiveListing(null)}
        style={{ width: width + 'px' }}
      >
        <div className={css.aspectWrapper}>
          <TourlyResponsiveImage
            rootClassName={css.rootForImage}
            alt={title}
            image={processedVariants}
            variants={StandardDPRVariants}
            sizes={CARD_SIZES}
            additionalParams={'blend-alpha=0'}
          />
          {googleRating > 0 ? (
            <span className={css.ratingView}>
              <div>
                {starRating(1)}
                {starRating(2)}
                {starRating(3)}
                {starRating(4)}
                {starRating(5)}
              </div>
              <div className={css.ratingValue}>{googleRating}</div>
            </span>
          ) : null}
          {discount < 0 ? (
            <span className={css.discountTag}>
              {intl.formatMessage({ id: 'ListingCard.discount' }, { discount: -discount })}
            </span>
          ) : null}
          {config.showChristmasDecorations ? (
            <div className={css.christmasfooterWrapper}>
              <img className={css.christmasfooterImg} src={christmasfooter} />
            </div>
          ) : null}
        </div>
      </div>
      <div className={css.info} style={{ width: width + 'px' }}>
        <div className={css.price}>
          {discount < 0 ? (
            <span>
              <div className={css.originalPriceValue} title={priceTitle}>
                {formattedPrice}
              </div>
              <div className={css.priceValue} title={priceTitle}>
                {priceDiscountedValue.formattedPrice}
              </div>
            </span>
          ) : (
            <div className={css.priceValue} title={priceTitle}>
              {formattedPrice}
            </div>
          )}
          <div className={css.perUnit}>
            <FormattedMessage id={unitTranslationKey} />
          </div>
        </div>
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          <div>
            <IconFreeCancellation />
            <span className={css.cancellationInfo}>
              <FormattedMessage id="ListingPage.FreeCancellation" />
            </span>
          </div>
          {typeof duration != 'undefined' ? (
            <div>
              <IconClock />
              <span className={css.durationInfo}>
                {intl.formatMessage({ id: 'EditListingDescriptionForm.duration.' + duration })}
              </span>
            </div>
          ) : null}
          {certificate && !certificate.hideFromListingInfo ? (
            <div>
              <IconBus />
              <span className={css.durationInfo}>
                {intl.formatMessage({ id: 'ListingPage.OfferPickupPoint' })}
              </span>
            </div>
          ) : null}
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  filtersConfig: config.custom.filters,
  setActiveListing: () => null,
  index: 0,
  width: 0
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  filtersConfig: array,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  index: number,
  setActiveListing: func,
  width: number
};

export default injectIntl(React.memo(ListingCardComponent));
