import React, { useEffect, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { propTypes } from '../../util/types';
import {
  SecondaryButton,
  IconGastronomy,
  IconAccessibility,
  IconHiking,
  IconMuseum,
  IconNature,
  IconRoad,
  IconWater,
  IconSeasonal,
} from '../../components';
import config from '../../config';
import { findOptionsForSelectFilter } from '../../util/search';
import css from './SearchFiltersCategories.module.css';
import useEmblaCarousel from 'embla-carousel-react'
import { useElementSize } from '@mantine/hooks';

const Card = (item) => {
  return (
     item.children
  );
}

const SearchFiltersCategories = props => {
  const { className, rootClassName, applyFilters } = props;
  const classes = clsx(rootClassName || css.root, className);

  const tourlyCategories = findOptionsForSelectFilter('tourlyCategories', config.custom.filters);

  const tourIcons = {
    boattours: <IconWater />,
    scubasnorkeling: <IconWater />,
    roadexcursions: <IconRoad />,
    offroad: <IconRoad />,
    gardensreserves: <IconNature />,
    hiking: <IconHiking />,
    sightseeing: <IconNature />,
    gastronomydrinks: <IconGastronomy />,
    culturemuseums: <IconMuseum />,
    seasonaltours: <IconSeasonal />,
    accessibility: <IconAccessibility />,
  };

  function updateCategory(category) {
    applyFilters(category);
  }

  const categoryArray =[]
  tourlyCategories.map(category =>
    tourIcons[category.key] !== undefined ? (
      categoryArray.push(<SecondaryButton
        key={category.key}
        className={css.categoryButton}
        onClick={() => updateCategory(category.key)}
      >
        {tourIcons[category.key]} {category.label}
      </SecondaryButton>)
    ) : null
  )

  const [emblaRef, emblaApi] = useEmblaCarousel(
    { 
      loop: false, 
      containScroll: 'keepSnaps',
      align: 'start',
      slidesToScroll: 'auto',
      watchResize: true,
    }
  )

  const [showPrevBtn, setShowPrevBtn] = useState(true)
  const [showNextBtn, setShowNextBtn] = useState(true)
  const { ref, width } = useElementSize(); // carousel element width
    
  const onSelect = useCallback(() => {
    setShowPrevBtn(emblaApi.canScrollPrev())
    setShowNextBtn(emblaApi.canScrollNext())
  }, [emblaApi])

  useEffect(() => {
    if (emblaApi) emblaApi.on('select', onSelect)
  }, [emblaApi, onSelect])

  useEffect(() => {
    if (emblaApi) emblaApi.on('init', onSelect)
  }, [emblaApi, onSelect])

  useEffect(() => {
    if (emblaApi) emblaApi.on('reInit', onSelect)
  }, [emblaApi, onSelect])

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev()
  }, [emblaApi])

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext()
  }, [emblaApi])

  useEffect(() =>{
    if(emblaApi){
      emblaApi.reInit()
    }
  },[width])

  return (
    <div className={css.emblaCarouselWrapper} ref={ref}>
      <div className={css.embla} ref={emblaRef}>
          <div className={css.embla__container}>
            {categoryArray ? categoryArray.map((item) => (
            <div className={css.embla__slide} key={Math.random()}>
              <Card>
                {item}
              </Card> 
            </div>
          )) : null}
        </div>
        {showPrevBtn ? 
          <button className={css.embla__button_prev} onClick={scrollPrev}>
          <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" style={{transform: 'rotate(90deg)'}}>
              <path d="M3.13523 6.15803C3.3241 5.95657 3.64052 5.94637 3.84197 6.13523L7.5 9.56464L11.158 6.13523C11.3595 5.94637 11.6759 5.95657 11.8648 6.15803C12.0536 6.35949 12.0434 6.67591 11.842 6.86477L7.84197 10.6148C7.64964 10.7951 7.35036 10.7951 7.15803 10.6148L3.15803 6.86477C2.95657 6.67591 2.94637 6.35949 3.13523 6.15803Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path>
            </svg>
          </button>
        :null}
        {showNextBtn ? 
          <button className={css.embla__button_next} onClick={scrollNext}>
            <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" style={{transform: 'rotate(-90deg)'}}>
              <path d="M3.13523 6.15803C3.3241 5.95657 3.64052 5.94637 3.84197 6.13523L7.5 9.56464L11.158 6.13523C11.3595 5.94637 11.6759 5.95657 11.8648 6.15803C12.0536 6.35949 12.0434 6.67591 11.842 6.86477L7.84197 10.6148C7.64964 10.7951 7.35036 10.7951 7.15803 10.6148L3.15803 6.86477C2.95657 6.67591 2.94637 6.35949 3.13523 6.15803Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path>
            </svg>
          </button>
        : null}
      </div>
    </div>
  );
};

SearchFiltersCategories.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
};

const { array, node, object, string } = PropTypes;

SearchFiltersCategories.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
};

export default React.memo(SearchFiltersCategories);
