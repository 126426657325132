import React from 'react';
import { bool, func, node, number, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import clsx from 'clsx';
import Switch from 'react-switch';

import css from './SearchFiltersPrimary.module.css';

const SearchFiltersPrimaryComponent = props => {
  const {
    rootClassName,
    className,
    children,
    sortByComponent,
    listingsAreLoaded,
    resultsCount,
    searchInProgress,
    isSecondaryFiltersOpen,
    toggleSecondaryFiltersOpen,
    selectedSecondaryFiltersCount,
    onMapIconClickDesktop,
    isSearchMapOpenOnDesktop,
  } = props;

  const hasNoResult = listingsAreLoaded && resultsCount === 0;
  const classes = clsx(rootClassName || css.root, className);

  const toggleSecondaryFiltersOpenButtonClasses =
    isSecondaryFiltersOpen || selectedSecondaryFiltersCount > 0
      ? css.searchFiltersPanelOpen
      : css.searchFiltersPanelClosed;
  const toggleSecondaryFiltersOpenButton = toggleSecondaryFiltersOpen ? (
    <button
      className={toggleSecondaryFiltersOpenButtonClasses}
      onClick={() => {
        toggleSecondaryFiltersOpen(!isSecondaryFiltersOpen);
      }}
    >
      <FormattedMessage
        id="SearchFiltersPrimary.moreFiltersButton"
        values={{ count: selectedSecondaryFiltersCount }}
      />
    </button>
  ) : null;

  return (
    <div className={classes}>
      <div className={css.searchOptions}>
        {listingsAreLoaded ? (
          <span className={css.searchResultSummary}>
            <span className={css.resultsFound}>
              <FormattedMessage
                id="SearchFiltersPrimary.foundResults"
                values={{ count: resultsCount }}
              />
            </span>
          </span>
        ) : null}
        {sortByComponent}
        <span className={css.filters}>
          {children}
          {toggleSecondaryFiltersOpenButton}
        </span>
        <div className={css.mapToggle}>
          <span className={css.showMapText}>
            <FormattedMessage id="SearchFiltersPrimary.showMap" />
          </span>
          <Switch
            onChange={onMapIconClickDesktop}
            checked={isSearchMapOpenOnDesktop}
            onColor="#1895c4"
            handleDiameter={22}
            uncheckedIcon={false}
            checkedIcon={false}
            uncheckedHandleIcon={
              <div
                className={css.handleIcon}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  borderRadius: '50%',
                  filter: 'brightness(80%)',
                }}
              />
            }
            checkedHandleIcon={
              <div
                className={css.handleIcon}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  borderRadius: '50%',
                }}
              />
            }
          />
        </div>
      </div>
      {hasNoResult ? (
        <div className={css.noSearchResults}>
          <span>
            <FormattedMessage id="SearchFiltersPrimary.noResults" />
          </span>
          <br></br>
          <br></br>
          <span className={css.noSearchResultsRegisterOperator}>
            <FormattedMessage id="SearchFiltersPrimary.noResultsDoYouProvide" />
            <a href="/LandingPageTO">
              <FormattedMessage id="SearchFiltersPrimary.noResultsRegisterOperator" />
            </a>
          </span>
        </div>
      ) : null}

      {searchInProgress ? (
        <div className={css.loadingResults}>
          <FormattedMessage id="SearchFiltersPrimary.loadingResults" />
        </div>
      ) : null}
    </div>
  );
};

SearchFiltersPrimaryComponent.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchInProgress: false,
  isSecondaryFiltersOpen: false,
  toggleSecondaryFiltersOpen: null,
  selectedSecondaryFiltersCount: 0,
  sortByComponent: null,
};

SearchFiltersPrimaryComponent.propTypes = {
  rootClassName: string,
  className: string,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  isSecondaryFiltersOpen: bool,
  toggleSecondaryFiltersOpen: func,
  selectedSecondaryFiltersCount: number,
  sortByComponent: node,
};

const SearchFiltersPrimary = SearchFiltersPrimaryComponent;

export default SearchFiltersPrimary;
