import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { propTypes } from '../../util/types';
import css from './SearchResultsPanel.module.css';
import { FormattedMessage } from '../../util/reactIntl';
import {
  ListingCard,
  PaginationLinks,
  SecondaryButton,
  IconAccessibility,
  IconCrafts,
  IconFitness,
  IconGastronomy,
  IconHiking,
  IconMuseum,
  IconSeasonal,
  IconNature,
  IconRadical,
  IconRoad,
  IconTransfers,
  IconWater,
  RecombeeRecommendations,
} from '../../components';

import config from '../../config';
import { findOptionsForSelectFilter } from '../../util/search';

import christmasTop from '../../assets/christmas_corner_top.png';
import christmasbottom from '../../assets/christmas_corner_bottom.png';
import { useViewportSize, useElementSize } from '@mantine/hooks';
import breakpoints from '../../styles/globals.module.scss';

const SearchResultsPanel = (props) => {
  const {
    className,
    rootClassName,
    listings,
    pagination,
    search,
    setActiveListing,
    applyFilters
  } = props;

  const { width: viewportWidth } = useViewportSize();
  const { ref, width: mainWidth } = useElementSize();
  const [searchResultsPanelWidth, setSearchResultsPanelWidth] = useState(0);
  useEffect(() => {
    //console.debug('viewportWidth=' + viewportWidth + ' || mainWidth=' + mainWidth);
    const padding = viewportWidth >= breakpoints.viewportLargePixels ? 36 : 24;
    //console.debug('At this width, margin will be =' + padding);
    const availableWidth = mainWidth != 0 && mainWidth < viewportWidth ? mainWidth : viewportWidth;
    //console.debug('Setting SearchResultsPanelWidth to ' + availableWidth - padding * 2);
    if (mainWidth>0) setSearchResultsPanelWidth(availableWidth - padding * 2);
  }, [viewportWidth, mainWidth]);


  const classes = clsx(rootClassName || css.root, className);

  const getListingCardClasses = () => {
    const numberOfListings = getNumberOfSlides();
    const nthElementClasses = {
      2: css.secondElement,
      3: css.thirdElement,
      4: css.thourthElement
    };
    const nthElClasse = numberOfListings>1 && numberOfListings<=4 ? nthElementClasses[numberOfListings] : "";
    return clsx(css.listingCard, nthElClasse);
  }

  const tourlyCategories = findOptionsForSelectFilter('tourlyCategories', config.custom.filters);

  // Groups every activity by category
  let categories = listings.reduce(function (r, listing) {
    r[listing.attributes.publicData.tourlyCategories[0]] =
      r[listing.attributes.publicData.tourlyCategories[0]] || [];
    r[listing.attributes.publicData.tourlyCategories[0]].push(listing);
    return r;
  }, Object.create(null));

  categories = Object.keys(categories)
    .sort(function (a, b) {
      return (
        tourlyCategories.map((a) => a.key).indexOf(a) -
        tourlyCategories.map((a) => a.key).indexOf(b)
      );
    })
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: categories[key],
      }),
      {}
    );

  const tourIcons = {
    boattours: <IconWater />,
    surfbodyboard: <IconWater />,
    kayaking: <IconWater />,
    scubasnorkeling: <IconWater />,
    biggamefishing: <IconWater />,
    transfers: <IconTransfers />,
    roadexcursions: <IconRoad />,
    offroad: <IconRoad />,
    gardensreserves: <IconNature />,
    hiking: <IconHiking />,
    canyoning: <IconRadical />,
    btt: <IconRadical />,
    sightseeing: <IconNature />,
    paragliding: <IconRadical />,
    gastronomydrinks: <IconGastronomy />,
    wine: <IconGastronomy />,
    wellbeing: <IconFitness />,
    fitness: <IconFitness />,
    culturemuseums: <IconMuseum />,
    craftsworkshops: <IconCrafts />,
    seasonaltours: <IconSeasonal />,
    accessibility: <IconAccessibility />,
  };

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="SearchPage"
        pageSearchParams={search}
        pagination={pagination}
      />
    ) : null;

  const categoryParams = search.pub_tourlyCategories ? search.pub_tourlyCategories : null;

  //Recombee recomendations object
  const showRecomms = config.recombeeConfig.searchPage;

  function updateCategory(category) {
    applyFilters(category);
  }

  // Calculate number of listing cards to be shown
  const columnGap = 24; // gap of 24px between slides
  const minimumSlideWidth = process.env.TOURLY_MINIMUM_SLIDE_WIDTH ?? 300; // minimum width of a slide
  const maximumNumberOfSlides = process.env.TOURLY_MAXIMUM_NUMBER_SLIDES ?? 4; // maximum number of slides to display

  const getNumberOfSlides = () => {
    if (searchResultsPanelWidth > 0) {
      // To avoid calculations while browser hasn't determined carousel's size
      // Number of slides = ((carouselWidth - (number of slides - 1) * columnGap)) divided (integer division) by minimumSlideWidth
      // simplified gives, Number of slides = (carouselWidth + columnGap) / (minimumSlideWidth + columnGap), where / is integer division
      // For example: For a viewport of 768, carouselWidth is 720 (768px - 24px * 2) and then Number of slides is 2, (720+24) / (300+24) =  2.293
      let numberOfSlides = Math.floor(
        (searchResultsPanelWidth + columnGap) / (minimumSlideWidth + columnGap)
      );
      numberOfSlides =
        numberOfSlides > maximumNumberOfSlides ? maximumNumberOfSlides : numberOfSlides;
      return numberOfSlides === 0 ? 1 : numberOfSlides;
    }
    return 1;
  };

  const getSlideWidth = () => {
    const numberOfSlides = getNumberOfSlides();
    return Math.round(
      (searchResultsPanelWidth - (numberOfSlides - 1) * columnGap) / numberOfSlides
    );
  };

  return (
    <div className={classes} ref={ref}>
      <div className={css.recombeeWrapper}>
        <RecombeeRecommendations useEmbla={true} showRecomms={showRecomms} largeTitle={true} />
      </div>
      <div className={css.searchResultsPanelTitle}>
        {categoryParams !== null ? (
          <h1>
            <div>
              {tourIcons[search.pub_tourlyCategories]}{' '}
              <span className={css.listingCategoryLink}>
                {tourlyCategories.find((cat) => cat.key === categoryParams.label)}
              </span>
            </div>
          </h1>
        ) : (
          <h1>
            <FormattedMessage id="SearchPage.searchResultsTitle" />
          </h1>
        )}
      </div>
      {searchResultsPanelWidth > 0 ? (
        <>
          <div className={css.listingCards}>
            {categoryParams !== null ? (
              <>
                {listings.map((l, i) => (
                  <ListingCard
                    className={getListingCardClasses()}
                    key={l.id.uuid}
                    listing={l}
                    setActiveListing={setActiveListing}
                    index={i}
                    width={getSlideWidth()}
                  />
                ))}
              </>
            ) : (
              listings.map((l, i) => (
                <ListingCard
                  className={getListingCardClasses()}
                  key={l.id.uuid}
                  listing={l}
                  setActiveListing={setActiveListing}
                  index={i}
                  width={getSlideWidth()}
                />
              ))
            )}
          </div>
          {props.children}
          {paginationLinks}
        </>
      ) : null}
    </div>
  );
};

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
};

const { array, node, object, string } = PropTypes;

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
};

export default SearchResultsPanel;
